var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "v_resource_center_detail_type_audio d-flex justify-center",
    },
    [
      _c(
        "div",
        { staticClass: "microsoft_file d-flex justify-center" },
        [
          _c("div", { staticClass: "mask" }),
          _c("task", {
            staticClass: "task_details_group",
            attrs: {
              max_width: "none",
              max_height: "none",
              box_shadow: "box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06)",
              show_intro: false,
              shopping_mail: true,
              border: "border: 1px solid #E8EEFF",
              sell: true,
              "show-side-bar": false,
              "show-title-icon": false,
              "show-title-status": false,
              "show-title-status-tag": false,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }