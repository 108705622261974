<script>
import Task from '@/components/Task/Container.vue';
import { PTask } from '@/types'
import { mapState } from 'vuex'
export default {
  components: {
    Task,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      // resourceFileList: state => state.resource.fileList,
      resourceFileData: state => state.resource.fileData,
    }),
    taskId () {
      const {
        resourceFileData
      } = this
      const {
        id,
      } = resourceFileData
      return id
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getTask()
    })
  },
  methods: {
    async getTask(){
      const {
        taskId
      } = this
      const res = await this.$api.shopping_mail.getTask({
        taskId
      })
      const { message, contents } = res;
      if (message === 'success') {
        const { task } = contents;
        this.$set(this, 'ptask', new PTask(task))
      }
      return res;
    },
  },
  // props: {
  //   taskId: {
  //     type: Number,
  //     default: 37633,
  //   },
  // },



}
</script>

<template>
  <div
    class="v_resource_center_detail_type_audio d-flex justify-center"
  >
    <div
      class="microsoft_file d-flex justify-center"
    >
      <div class="mask"></div>
      <task
        class="task_details_group"
        :max_width="'none'"
        :max_height="'none'"
        :box_shadow="'box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06)'"
        :show_intro="false"
        :shopping_mail="true"
        :border="'border: 1px solid #E8EEFF'"
        :sell="true"
        :show-side-bar="false"
        :show-title-icon="false"
        :show-title-status="false"
        :show-title-status-tag="false"
      ></task>
    </div>
  </div>
</template>

<style scoped lang="scss">
.v_resource_center_detail_type_audio {
  width: 100%;
  overflow: auto;
  margin: -20px;
  .microsoft_file {
    width: 100%;
    max-width: 1000px;
    position: relative;
    overflow: auto;
    .task_details_group{
      position: relative;
      z-index : 2;
    }
    .mask{
      position: absolute;
      z-index : 3;
      width   : 100%;
      height  : 100%;
      left    : 0;
      top     : 0;
    }
    .img_wrapper {
      width: 100%;
      img {
        max-width: 90%;
      }
    }
  }
}
</style>
