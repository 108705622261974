<script>
import { mapState } from 'vuex'
import VideoDetail from '@/views/fun_template/resource/resource_center/detail_type/video'
import MicrosoftFileDetail from '@/views/fun_template/resource/resource_center/detail_type/microsoft_file'
import LinkDetail from '@/views/fun_template/resource/resource_center/detail_type/link'
import AudioDetail from '@/views/fun_template/resource/resource_center/detail_type/audio'
import PdfDetail from '@/views/fun_template/resource/resource_center/detail_type/pdf'
import TaskDetail from '@/views/fun_template/resource/resource_center/detail_type/task'
import PicDetail from '@/views/fun_template/resource/resource_center/detail_type/pic'
import ArDetail from '@/views/fun_template/resource/resource_center/detail_type/ar'
import UnknownDetail from '@/views/fun_template/resource/resource_center/detail_type/unknown'
import oss from '@/api/modules/oss'
import global from '@/api/global'
export default {
  components: {
    VideoDetail,
    MicrosoftFileDetail,
    LinkDetail,
    AudioDetail,
    PdfDetail,
    PicDetail,
    TaskDetail,
    ArDetail,
    UnknownDetail,
  },
  computed: {
    ...mapState({
      // resourceFileList: state => state.resource.fileList,
      resourceFileData: state => state.resource.fileData,
    }),
    showVideo () {
      const {
        resourceFileData
      } = this
      if (!resourceFileData) {
        return false
      }
      const {
        fileType
      } = resourceFileData
      return fileType === 'video'
    },
    showMicroSoftFile () {
      const {
        resourceFileData
      } = this
      if (!resourceFileData) {
        return false
      }
      const {
        fileType
      } = resourceFileData
      return fileType === 'word' || fileType === 'excel' || fileType === 'ppt'
    },
    showLink () {
      const {
        resourceFileData
      } = this
      if (!resourceFileData) {
        return false
      }
      const {
        fileType
      } = resourceFileData
      return fileType === 'link'
    },
    showAudio () {
      const {
        resourceFileData
      } = this
      if (!resourceFileData) {
        return false
      }
      const {
        fileType
      } = resourceFileData
      return fileType === 'audio'
    },
    showPDF () {
      const {
        resourceFileData
      } = this
      if (!resourceFileData) {
        return false
      }
      const {
        fileType
      } = resourceFileData
      return fileType === 'pdf'
    },
    showPic () {
      const {
        resourceFileData
      } = this
      if (!resourceFileData) {
        return false
      }
      const {
        fileType
      } = resourceFileData
      return fileType === 'pic'
    },
    showAr () {
      const {
        resourceFileData
      } = this
      if (!resourceFileData) {
        return false
      }
      const {
        fileType
      } = resourceFileData
      return fileType === 'vr'
    },
    showUnknown () {
      const {
        resourceFileData
      } = this
      if (!resourceFileData) {
        return false
      }
      const {
        fileType
      } = resourceFileData
      return fileType === 'unknown'
    },
    showDownload () {
      const {
        resourceFileData
      } = this

      const {
        fileType
      } = resourceFileData
      if (fileType === 'task') {
        return false
      }
      if (resourceFileData.type === 3) {
        return false
      }
      return true
    },
    showLike () {
      const {
        resourceFileData
      } = this
      const {
        fileType
      } = resourceFileData
      if (fileType === 'vr') {
        return false
      }
      return true
    },
    showTask () {
      const {
        resourceFileData
      } = this
      const {
        fileType
      } = resourceFileData
      return fileType === 'task'
    },
    showTitle () {
      const {
        resourceFileData
      } = this
      const {
        fileType
      } = resourceFileData
      return fileType !== 'task'
    },
    browseCount () {
      const {
        resourceFileData
      } = this
      const {
        browseCount,
        readamount,
      } = resourceFileData
      return browseCount || readamount || 0
    }
  },
  mounted () {
    this.doBrowse()
    this.getDetail()
  },
  methods: {
    download () {
      const {
        resourceFileData
      } = this
      const {
        fileType,
        url,
        filename,
      } = resourceFileData

      if (fileType === 'vr') {
        oss.downloadFile({
          file: {
            url,
            filename
          }
        })
        return
      }
      const {
        file
      } = resourceFileData
      oss.downloadFile({ file })
    },
    async doLike () {
      const {
        resourceFileData
      } = this
      const {
        likeFlag,
      } = resourceFileData
      const  res = await this.resourceFileData.doLike()
      if (res.message === 'success') {
        this.$notice ({
          desc: likeFlag ? '取消成功' : '点赞成功',
          type: 'success'
        })
      }
    },
    async touchCollent () {
      const {
        resourceFileData
      } = this
      const {
        collectionflag,
        fileType
      } = resourceFileData

      if (fileType === 'task') {
        if (collectionflag) {
          const res = await this.$global.doPost({
            url: '/projectCustom/deleteFolderTask',
            data: {
              deleteIds: resourceFileData.id,
            }
          })
          if (res.message === 'success') {
            resourceFileData.collectionflag = 0
            this.$notice({ desc:"移除成功", type: 'success' })
          }
        } else {
          this.$collectSelectRoute({
            onSure: async (params)=>{
              const { p_folder } = params
              const res = await global.doPost({
                url: '/projectCustom/addFolderTask',
                data: {
                  folderId: p_folder.id,
                  addIds: resourceFileData.id,
                }
              })
              if(res.message === 'success'){
                resourceFileData.collectionflag = 1
                this.$notice({ desc:"收藏成功", type: 'success' })
              }
            }
          })
        }
        return
      }
      this.$collectSelectRoute({
        onSure: async (params) => {
          const res = await global.doPost({
            url: '/projectCustom/customTeackFile',
            data: {
              teackfileId: resourceFileData.id,
              folderId: params.p_folder.id,
            }
          })
          if(res.message === 'success'){
            resourceFileData.collectCount += 1
            resourceFileData.customFlag = 1
            this.$notice({desc:"收藏成功", type: 'success'})
          }
        }
      })
    },
    // 浏览量+1
    async doBrowse () {
      const {
        resourceFileData
      } = this
      const {
        fileType
      } = resourceFileData
      // // 如果文件类型是'task'，则不执行任何操作并退出当前函数
      if (fileType === 'task') {
        resourceFileData.readamount += 1
        return
      }
      const res = await resourceFileData.doBrowse()
      if(res.message === 'success'){
        // resourceFileData.browseCount += 1
      }
    },
    // 获取详情
    async getDetail () {
      const {
        resourceFileData
      } = this
      const {
        fileType
      } = resourceFileData

      // 如果文件类型是'task'，则不执行任何操作并退出当前函数
      if (fileType === 'task') {
        return
      }
      const res = await resourceFileData.getDetail()
      // if(res.message === 'success'){
      //   resourceFileData.content = res.data.content
      // }
    }
  }
}
</script>

<template>
<div
  v-if="resourceFileData"
  class="v_resource_resource_center_detail bbox_wrapper"
>
  <div
    class="detail_wrapper d-flex align-stretch justify-space-between"
  >
    <div
      class="detail_group"
    >
      <div
        v-if="showTitle"
        class="title"
      >{{ resourceFileData.fileName }}</div>
      <div
        class="detail_content_wrapper d-flex justify-center"
      >
        <TaskDetail
          v-if="showTask"
        ></TaskDetail>
        <VideoDetail
          v-if="showVideo"
        ></VideoDetail>
        <MicrosoftFileDetail
          v-if="showMicroSoftFile"
        ></MicrosoftFileDetail>
        <LinkDetail
          v-if="showLink"
        ></LinkDetail>
        <AudioDetail
          v-if="showAudio"
        ></AudioDetail>
        <PdfDetail
          v-if="showPDF"
        ></PdfDetail>
        <PicDetail
          v-if="showPic"
        ></PicDetail>
        <ArDetail
          v-if="showAr"
        ></ArDetail>
        <UnknownDetail
          v-if="showUnknown"
        ></UnknownDetail>
      </div>
      <div
        class="fun_wrapper d-flex align-center justify-space-between"
      >
        <div
          class="number_wrapper d-flex align-center"
        >
          <div
            class="number_group d-flex align-center"
          >
            <div
              class="icon view"
            ></div>
            <div
              class="number"
            >{{ browseCount }}</div>
          </div>
          <div
            class="number_group d-flex align-center"
          >
            <div
              class="icon call"
            ></div>
            <div
              class="number"
            >
              {{ resourceFileData && resourceFileData.courselist && resourceFileData.courselist.length}}
            </div>
          </div>
          <div
            class="number_group d-flex align-center"
          >
            <div
              class="icon like"

            ></div>
            <div
              class="number"
            >{{ resourceFileData.likeCount || 0 }}</div>
          </div>
          <div
            class="number_group d-flex align-center"
          >
            <div
              class="icon collect"
            ></div>
            <div
              class="number"
            >{{ resourceFileData.collectCount || 0 }}</div>
          </div>
        </div>
        <div
          class="fun_group d-flex align-center"
        >
          <div
            v-if="showLike"
            class="btn cp like"
            :class="{
              on: resourceFileData.likeFlag
            }"
            @click="doLike"
          ></div>
          <div
            class="btn cp collect"
            :class="{
              on: resourceFileData.customFlag
            }"
            @click="touchCollent"
          ></div>
          <div
            v-if="showDownload"
            class="btn cp download"
            @click="download"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="detail_info_group d-flex align-start justify-space-between flex-shrink-0"
    >
      <div
        class="info_group"
      >
        <div
          class="title"
        >
          资源信息
        </div>
        <div
          class="line_group d-flex align-start"
        >
          <div
            class="key flex-shrink-0"
          >资源作者:</div>
          <div
            class="value"
          >{{ resourceFileData.userName || resourceFileData.updateusername }}</div>
        </div>
        <div
          v-if="resourceFileData.file || showAr"
          class="line_group d-flex align-start"
        >
          <div
            class="key flex-shrink-0"
          >资源大小:</div>
          <div
            class="value"
          >{{ resourceFileData.file ? resourceFileData.file.formatSize : resourceFileData.formatSize }}</div>
        </div>
        <div
          class="line_group d-flex align-start"
        >
          <div
            class="key flex-shrink-0"
          >所属专业:</div>
          <div
            class="value"
          >
            {{ resourceFileData && resourceFileData.major }}
          </div>
        </div>
        <div
          class="line_group d-flex align-start"
        >
          <div
            class="key flex-shrink-0"
          >单位:</div>
          <div
            class="value"
          >{{ resourceFileData.ownedEnterprises }}</div>
        </div>
        <div
          class="line_group d-flex align-start"
        >
          <div
            class="key flex-shrink-0"
          >媒体类型:</div>
          <div
            class="value"
          >{{ resourceFileData.typeText }}</div>
        </div>
        <div
          class="line_group d-flex align-start"
        >
          <div
            class="key flex-shrink-0"
          >应用类型:</div>
          <div
            class="value"
          >
            {{ resourceFileData && resourceFileData.convertTeachType }}
          </div>
        </div>
        <div
          class="line_group d-flex align-start"
        >
          <div
            class="key flex-shrink-0"
          >课程:</div>
          <div
            class="value"
          >
            {{ resourceFileData && resourceFileData.convertCourseName }}
          </div>
        </div>
        <div
          class="line_group d-flex align-start"
        >
          <div
            class="key flex-shrink-0"
          >创建时间:</div>
          <div
            class="value"
          >{{ $moment(resourceFileData.createTime).format('YYYY-MM-DD') }}</div>
        </div>
      </div>
      <div
        class="fun_group"
      ></div>
    </div>
  </div>
  <div
    class="relation_wrapper"
  >
    <div
      class="title"
    ></div>
    <div
      class="list_wrapper"
    >
      <template
        v-for="(item, index) in 10"
      >
        <div
          :key="index"
          class="course_wrapper"
        ></div>
      </template>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.v_resource_resource_center_detail {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  overflow: auto;
  .detail_wrapper {
    padding: 20px 30px;
    .detail_group {
      width: 100%;
      padding: 30px;
      background-color: #fff;
      .title {
        font-weight: bold;
        font-size: 24px;
        color: #333333;
        line-height: 31px;
        text-align: center;
        font-style: normal;
        padding-bottom: 20px;
      }
      .detail_content_wrapper {
        width: 100%;
        padding: 20px;
      }
      .fun_wrapper {
        width: 100%;
        padding-top: 20px;
        .number_wrapper {
          .number_group {
            padding-right: 20px;
            .icon {
              @include background(18px 18px);
              width: 24px;
              height: 24px;
              margin-right: 3px;
              &.view {
                @include background(23px, 18px);
                background-image: url(~@/assets/images/resource/detail_view.png);
              }
              &.call {
                background-image: url(~@/assets/images/resource/detail_call.png);
              }
              &.like {
                background-image: url(~@/assets/images/resource/detail_like.png);
              }
              &.collect {
                background-image: url(~@/assets/images/resource/detail_collect.png);
              }
            }
            .number {
              font-size: 16px;
              color: #999999;
              line-height: 21px;
              text-align: left;
              font-style: normal;
            }
          }
        }
        .fun_group {
          .btn {
            @include transition;
            width: 36px;
            height: 36px;
            margin-left: 24px;
            cursor: pointer;
            &.like {
              background-image: url(~@/assets/images/course_package/like_btn.png);
              &.on {
                background-image: url(~@/assets/images/course_package/like_btn_on.png);
              }
            }
            &.collect {
              background-image: url(~@/assets/images/course_package/collect_btn.png);
              &.on {
                background-image: url(~@/assets/images/course_package/collect_btn_on.png);
              }
            }
            &.download {
              background-image: url(~@/assets/images/course_package/download_btn.png);
              &:hover {
                background-image: url(~@/assets/images/course_package/download_btn_on.png);
              }
            }
          }
        }
      }
    }
    .detail_info_group {
      background-color: #fff;
      width: 400px;
      border-radius: 4px;
      padding: 30px;
      margin-left: 20px;
      .info_group {


        .title {
          font-weight: bold;
          font-size: 16px;
          color: #333333;
          line-height: 21px;
          text-align: left;
          font-style: normal;
          padding-bottom: 30px;
        }
        .line_group {
          padding-bottom: 10px;
          .key {
            width: 60px;
            font-size: 14px;
            color: #666666;
            line-height: 19px;
            text-align: justify;
            text-align-last: justify;
            font-style: normal;
            margin-right: 10px;
          }
          .value {
            font-size: 14px;
            color: #333333;
            line-height: 19px;
            text-align: left;
            font-style: normal;
          }
        }

      }
      .fun_group {

      }
    }
  }
}
</style>
